<template>
  <div>
    <v-container fluid fill-height class="pa-6">
      <v-toolbar flat class="mb-8">
        <v-toolbar-title>Create Brief</v-toolbar-title>
      </v-toolbar>

      <v-progress-linear
        color="deep-purple accent-4"
        :active="loading"
        :indeterminate="loading"
      ></v-progress-linear>

      <v-row align="center" justify="center">
        <v-col id="driver" cols="5" sm="5" md="5">
          <v-card elevation="0" class="py-5 px-2 mx-auto">
            <v-form ref="form" v-model="valid">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                    id="v-step-2"
                  >
                    <v-icon color="black">mdi-office-building-outline </v-icon>
                    Brand
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field
                      v-model.trim="currentBrief.brand"
                      :rules="rules.fieldRules"
                      required
                      filled
                      label="Brand Name"
                    >
                    </v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                  >
                    <v-icon color="black">mdi-cash-check</v-icon>
                    Budget Allocation
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field
                      v-model.trim="currentBrief.budget"
                      :rules="rules.fieldRules"
                      min="0"
                      type="number"
                      suffix="R"
                      required
                      filled
                      label="Input Budget"
                    >
                    </v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                  >
                    <v-icon color="black">mdi-map-marker-outline</v-icon>
                    Local Municipality of Focus
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-combobox
                      :items="getRegions"
                      v-model="currentBrief.region"
                      name="region"
                      item-color="secondary"
                      :rules="rules.selectRules"
                      multiple
                      chips
                      label="Local Municipality"
                    ></v-combobox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                  >
                    <v-icon color="black">mdi-compass-outline</v-icon>
                    Campaign Type
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row align="center">
                      <v-select
                        v-model="currentBrief.campaignType"
                        label="Campaign"
                        :items="campaigns"
                        :rules="rules.fieldRules"
                      ></v-select>
                      <v-btn
                        class="ml-2"
                        icon
                        color="blue"
                        @click="showCampaignsDialog = true"
                      >
                        <v-icon title="More information"
                          >mdi-progress-question
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                  >
                    <v-icon color="black">mdi-account-circle-outline</v-icon>
                    Social Economic Class (SEC)
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-combobox
                      :items="secs"
                      v-model="currentBrief.sec"
                      name="sec"
                      item-color="secondary"
                      multiple
                      label="SEC"
                      chips
                      :rules="rules.selectRules"
                    ></v-combobox>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                  >
                    <v-icon color="black">mdi-desktop-mac</v-icon>
                    Target
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-combobox
                          :items="ages"
                          v-model="currentBrief.ageGroup"
                          name="ages"
                          label="Age Group"
                          item-color="secondary"
                          multiple
                          :rules="rules.selectRules"
                          small-chips
                        ></v-combobox>
                      </v-row>
                      <v-row align="center" justify="center">
                        <v-combobox
                          :items="genders"
                          v-model="currentBrief.gender"
                          name="genders"
                          item-color="secondary"
                          multiple
                          label="Gender"
                          :rules="rules.selectRules"
                          small-chips
                        ></v-combobox>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                  >
                    <v-icon color="black">mdi-calendar-month-outline</v-icon>
                    Campaign Duration
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container fill-height fluid>
                      <v-row align="center" justify="center">
                        <v-menu
                          v-model="startMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currentBrief.start_date"
                              label="Start date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="rules.fieldRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentBrief.start_date"
                            @change="startMenu = false"
                            :min="nowDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-row>

                      <v-row align="center" justify="center">
                        <v-menu
                          v-model="endMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currentBrief.end_date"
                              label="End date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              :rules="rules.fieldRules"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentBrief.end_date"
                            :min="nowDate"
                            @change="endMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    color="expansionPanels"
                    expand-icon="mdi-menu-right"
                  >
                    <v-icon color="black">mdi-notebook-edit</v-icon>
                    More Info.
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-textarea
                      filled
                      color="secondary"
                      name="input-7-4"
                      label="Any request not captured ?"
                      v-model="currentBrief.description"
                    ></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
          </v-card>
        </v-col>

        <v-divider></v-divider>

        <v-col cols="7" sm="7" md="7">
          <brief-details :currentBrief="currentBrief"></brief-details>
        </v-col>
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="submitBrief"> Save </v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>

    <v-dialog max-width="450" v-model="showCampaignsDialog">
      <v-card class="pa-5">
        <v-card-text>
          <p>
            <span class="text-h6">Launch Campaign</span> - This is for new brand
            that want to create awareness or a dormant brand that want to
            revive. The focus for such campaign is to drive awareness and
            therefore the plan is to use frequency inventories to drive this
            campaign.
          </p>
          <p>
            <span class="text-h6">Sustenance Campaign </span> - This is for
            existing brand that have a particular promotion they want to push at
            a particular time of the year. This campaign is normally for an
            already established brands and such campaigns are meant to solve a
            specific problem of the brand or push sales and are therefore very
            focused. Outdoor selection for such campaign are very tactical since
            we have a more focused reason for this campaign.
          </p>
          <p>
            <span class="text-h6">Disruptive Campaign</span> - Disruptive
            campaign are mainly to counter competition at a particular point of
            the year. Focus for such campaign are skewed towards how to outdo
            what competition is doing at the same or about to do.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showCampaignsDialog = false" color="error"
            >Close</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alert></alert>
  </div>
</template>

<script>
import BriefDetails from "./BriefDetails.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      startMenu: false,
      endMenu: false,
      nowDate: new Date().toISOString().slice(0, 10),

      rules: {
        fieldRules: [(v) => !!v || "Field is required. Please Fill."],
        selectRules: [
          (v) =>
            v.length > 0 ||
            "Item is required. Please Select at least one option",
        ],
      },

      valid: false,
      loading: false,
      showCampaignsDialog: false,
    };
  },

  components: { BriefDetails },

  props: {
    currentBrief: {
      type: Object,
      default: () => ({
        id: null,
        brand: null,
        budget: 0,
        region: [],
        campaignType: null,
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: null,
        end_date: null,
        description: null,
      }),
    },
    operation: String,
  },

  computed: {
    ...mapGetters({
      getCurrentUser: "getCurrentUser",
      getRegions: "regions/getRegions",
    }),

    campaigns() {
      return ["Tactical Campaign", "Sustenance Campaign", "Launch Campaign"];
    },

    // TODO: get sec from db and map
    secs() {
      return ["A", "B", "C", "D", "E"];
    },

    ages() {
      return [
        "0-4",
        "5-9",
        "10-14",
        "15-19",
        "20-24",
        "25-29",
        "30-34",
        "35-39",
        "40-44",
        "45-49",
        "50-54",
        "55-59",
        "60-64",
        "65-69",
        "70-74",
        "75-79",
        "80+",
      ];
    },

    genders() {
      return ["Male", "Female"];
    },
  },

  methods: {
    ...mapActions({
      addBrief: "briefs/addBrief",
      updateBrief: "briefs/updateBrief",
      fetchBriefs: "briefs/fetchBriefs",
      fetchAllRegions: "regions/fetchAllRegions",
    }),

    async submitBrief() {
      this.$refs.form.validate();

      if (!this.valid) return;

      this.loading = true;

      //
      this.currentBrief.email = this.getCurrentUser.email;

      if (this.operation === "update") {
        await this.updateBrief(this.currentBrief);
      } else {
        await this.addBrief(this.currentBrief);
      }
      this.loading = false;
      this.$refs.form.reset();
      await this.fetchBriefs();
    },
  },

  async mounted() {
    await this.fetchAllRegions();
  },
};
</script>

<style scoped>
.container {
  background: #fff;
}
#driver {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
}

.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}

.v-expansion-panel {
  margin-top: 10px;
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none !important;
}
.v-expansion-panel-header > .v-icon {
  margin-right: auto;
}

.v-expansion-panel-content {
  padding: 16px !important;
}
</style>
