<template>
  <v-container fill-height fluid>
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar flat>
      <v-toolbar-title>Create Campaign</v-toolbar-title>
    </v-toolbar>

    <v-row class="controller py-1">
      <div class="text-center">
        <v-dialog v-model="showBriefListDialog" width="70%">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="accent" v-bind="attrs" v-on="on">Select Brief</v-btn>
          </template>
          <v-card>
            <v-toolbar>
              <v-spacer></v-spacer>

              <v-btn icon @click="showBriefListDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <briefs-list
              :viewOnCampaignsDialog="true"
              @select-brief="closeDialog"
            ></briefs-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="closeDialog">Close</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="controller__options">
        <p>VIEW:</p>

        <v-btn
          v-for="btn in buttons"
          elevation="0"
          :key="btn.name"
          @click="setComponent(btn.cmp)"
          :class="{ active: btn.cmp === currentComponent }"
        >
          <v-icon color="black">{{ btn.icon }}</v-icon>
          {{ btn.name }}
        </v-btn>
      </div>
    </v-row>

    <v-row
      class="pa-2 my-3 mx-auto"
      justify="center"
      align="center"
      v-if="getBriefsCart"
      id="priceDriver"
    >
      <div>
        <v-row>
          <div>Brief Budget: {{ getBriefsCart.budget }} R</div>
        </v-row>
        <v-row>
          <div>Total Billboards Prices: {{ totalBbsCost }} R</div>
        </v-row>
        <v-row>Price Difference: {{ budgetDiff() }} R</v-row>
      </div>
    </v-row>

    <v-row>
      <v-col cols="12" lg="3" md="3" xl="3">
        <insights-filter></insights-filter>
      </v-col>
      <v-col cols="12" lg="9" md="9" xl="9">
        <keep-alive>
          <component :is="currentComponent" v-bind="toggleInsights"></component>
        </keep-alive>
      </v-col>
    </v-row>
    <alert></alert>
  </v-container>
</template>

<script>
import CampaignMap from "@/components/campaign/CampaignMap.vue";
import BriefsList from "@/components/briefs/BriefsList.vue";

import { mapGetters, mapActions } from "vuex";

import { handleHttpError } from "@/helpers/utils.js";
import EventBus from "@/components/events/EventBus";
import InsightsFilter from "../insights/InsightsFilter.vue";
import InsightsList from "../insights/InsightsList.vue";

export default {
  data() {
    return {
      loading: false,
      totalBbsCost: 0,
      showBriefListDialog: false,
      currentComponent: "CampaignMap",
      buttons: [
        { name: "Map", icon: "mdi-map-legend", cmp: "CampaignMap" },
        { name: "Table", icon: "mdi-table-large", cmp: "InsightsList" },
      ],
    };
  },

  components: {
    CampaignMap,
    BriefsList,
    InsightsFilter,
    InsightsList,
  },
  computed: {
    ...mapGetters({
      getBriefsCart: "briefs/getBriefsCart",
    }),

    toggleInsights() {
      return this.currentComponent === "InsightsList"
        ? { viewOnCampaignCreation: true }
        : { viewOnCampaignCreation: false };
    },
  },

  methods: {
    ...mapActions({ fetchBBPaginated: "billboards/fetchBBPaginated" }),

    setComponent(cmp) {
      this.currentComponent = cmp;
    },
    closeDialog() {
      this.showBriefListDialog = false;
    },
    totalBbsPrice(price) {
      let cost = parseInt(price);
      if (!cost) cost = 0;

      this.totalBbsCost += cost;
    },

    budgetDiff() {
      const briefBudg = parseInt(this.getBriefsCart.budget) || 0;

      const diff = briefBudg - this.totalBbsCost;

      if (Math.sign(diff) > 0) {
        return `${diff}`;
      } else {
        return `-${diff}`;
      }
    },
  },

  watch: {
    showBriefListDialog(state) {
      // dialog is being closed and no brief has been selected
      if (!state && !this.getBriefsCart) {
        handleHttpError("No brief was selected");
      }
    },
  },

  async mounted() {
    EventBus.$on("bbPrice", (val) => {
      this.totalBbsPrice(val);
    });
  },
};
</script>

<style scoped>
.container {
  background: #fff;
}
.controller {
  display: flex;
  justify-content: space-between;
}

.controller__options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.controller__options p {
  margin: 0;
  padding-right: 10px;
}

.v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
  text-transform: none;
}
.active {
  background: #6a5a26 !important;
}

#priceDriver {
  font-family: Manrope;
}
</style>
