<template>
  <v-card width="100%" elevation="0" class="px-2 mx-auto">
    <v-overlay :value="loading">
      <v-progress-circular
        color="secondary"
        size="70"
        width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card-title>Campaign Details</v-card-title>

    <div class="controller__options">
      <p>Export:</p>

      <download-excel
        :data="campaign.billboards"
        :fields="excelExportFields"
        :name="exportName + '.xls'"
        :before-generate="showLoading"
        :before-finish="removeLoading"
      >
        <v-btn color="secondary lighten-1" elevation="0"
          >Excel <v-icon color="white">mdi-microsoft-excel </v-icon>
        </v-btn>
      </download-excel>
    </div>

    <v-row>
      <v-col cols="6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Name:
                <span class="v-list-item-span">{{ campaign_name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Budget:

                <span class="v-list-item-span">{{ parseBudget() }} R</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Plan By:
                <span class="v-list-item-span">{{ planner_name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Planner Organization:
                <span class="v-list-item-span">{{ planner_organization }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Created On:
                <span class="v-list-item-span">{{ createdOn }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Brand:

                <span class="v-list-item-span">{{ brand_name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Duration:

                <span class="v-list-item-span">{{ duration() }} Days</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Start Date:

                <span class="v-list-item-span">{{ start_date }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >End Date:

                <span class="v-list-item-span">{{ end_date }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Sites:

                <span class="v-list-item-span">{{ sites }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-card width="100%">
        <v-toolbar height="34" flat color="expansionPanels" class="my-1">
          <v-spacer />
          <v-toolbar-title class="black--text">
            Billboards Sites
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <monitoring-list :currentCampaign="campaign"></monitoring-list>
      </v-card>
    </v-row>

    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("campaigns");

import * as dayjs from "dayjs";

import MonitoringList from "../monitoring/MonitoringList.vue";

export default {
  data() {
    return {
      campaign: {
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        billboards: [
          {
            id: null,
            latitude: null,
            longitude: null,
            images: [{ path: null }],
          },
        ],
        planner: {
          user: {
            organization: null,
            planner_name: null,
          },
        },
        brief: {
          name: null,
          brand: {
            budget: 0,
          },
        },
      },
      loading: false,

      headers: [
        { text: "#", align: "start", sortable: false, value: "number" },
        { text: "Image", sortable: false, value: "images" },
        { text: "Lighting", value: "lighting" },
        { text: "Price(R)", value: "rate_card" },
        { text: "Length (Metres)", value: "length" },
        { text: "Width (Metres)", value: "width" },
        { text: "Height", value: "height" },
        { text: "Clutter", value: "clutter" },
        { text: "Faces", value: "faces" },
        { text: "Orientation", value: "orientation" },
        { text: "Site Run Up(m)", value: "site_run_up" },
        { text: "Medium", value: "medium" },
        { text: "Address", value: "address" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCurrentCampaign"]),

    excelExportFields() {
      return {
        "Price (R)": "rate_card",
        "Current Brand": "brand_name",
        Lighting: "lighting",
        "Length (Metres)": "length",
        "Width (Metres)": "width",
        Clutter: "clutter",
        Medium: "medium",
        Orientation: "orientation",
        Faces: "faces",
        Address: "address",
        "Site Run Up (m)": "site_run_up",
        Images: {
          field: "images",
          callback: (val) => {
            return `${val[0].path},`;
          },
        },
      };
    },
    exportName() {
      return `${this.campaign_name}_${this.createdOn}`;
    },
    campaign_name() {
      return this.campaign.name;
    },
    planner_name() {
      return this.campaign.planner.user.plannerName;
    },
    planner_organization() {
      return this.campaign.planner.user.organization;
    },
    createdOn() {
      return new Date(this.campaign.createdAt).toDateString();
    },
    brand_name() {
      return this.campaign.brief.brand.name;
    },
    start_date() {
      return new Date(this.campaign.brief.start_date).toDateString();
    },
    end_date() {
      return new Date(this.campaign.brief.end_date).toDateString();
    },
    sites() {
      return this.campaign.billboards.length;
    },
    bbs() {
      return this.campaign.billboards;
    },
    exportBBS() {
      return this.campaign.billboards;
    },
  },

  components: { MonitoringList },

  methods: {
    ...mapActions(["fetchCampaign"]),

    showLoading() {
      this.exportProgress = true;
    },
    removeLoading() {
      this.exportProgress = false;
    },

    parseBudget() {
      if (!this.campaign.brief.budget) return 0;
      return new Intl.NumberFormat().format(this.campaign.brief.budget);
    },

    duration() {
      let end_date = dayjs(this.end_date);
      let start_date = dayjs(this.start_date);

      let diff = end_date.diff(start_date, "d");

      if (!diff) return 0;
      return diff;
    },
  },
  watch: {
    getCurrentCampaign: {
      async handler(val) {
        if (!val) return;
        this.campaign = await this.fetchCampaign(val);
      },
    },
  },
  async mounted() {
    this.loading = true;
    this.campaign = await this.fetchCampaign(this.getCurrentCampaign);
    this.loading = false;
  },
};
</script>

<style scoped>
.v-card__title {
  padding: 5px;
  color: black;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}
.controller__options {
  /* margin: 10px 0; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.controller__options p {
  margin: 0;
  padding-right: 10px;
}
.controller__options > .v-btn {
  background: rgba(143, 146, 161, 0.05);
  border-radius: 32px;
  margin: 0 10px;
}

.v-list-item-span {
  color: var(--v-textGray-base) !important;
}
</style>
