<template>
  <v-card id="bus" class="pa-3" elevation="0">
    <v-card-title class="pa-1">Brief Details</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            Brand Name:
            <span class="v-list-item-span secondary--text">
              {{ currentBrief.brand }}
            </span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            Budget Allocation:
            <span class="v-list-item-span secondary--text">
              R {{ parseBudget() }}
            </span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            Region of Focus:
            <span
              class="v-list-item-span"
              v-for="reg in currentBrief.region"
              :key="reg"
              >{{ reg }},</span
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          ><v-list-item-title
            >Campaign Type:
            <span class="v-list-item-span">{{
              currentBrief.campaignType
            }}</span>
          </v-list-item-title></v-list-item
        >
        <v-list-item
          ><v-list-item-title
            >SEC:
            <span
              class="v-list-item-span"
              v-for="el in currentBrief.sec"
              :key="el"
              >{{ el }},
            </span>
          </v-list-item-title></v-list-item
        >
        <v-list-item
          ><v-list-item-title
            >Target:
            <span
              class="v-list-item-span"
              v-for="el in currentBrief.gender"
              :key="el"
              >{{ el }},</span
            >
            <span
              class="v-list-item-span"
              v-for="el in currentBrief.ageGroup"
              :key="el"
              >{{ el }},</span
            >
          </v-list-item-title></v-list-item
        >
        <v-list-item>
          <v-list-item-title
            >Duration:

            <span class="v-list-item-span" v-if="currentBrief.end_date"
              >: {{ start_date }} to {{ end_date }}</span
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>More Brief Notes: </v-list-item-title>
            <v-list-item-subtitle>
              {{ currentBrief.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import * as dayjs from "dayjs";

export default {
  computed: {
    start_date() {
      return new Date(this.currentBrief.start_date).toDateString();
    },
    end_date() {
      return new Date(this.currentBrief.end_date).toDateString();
    },
  },
  props: {
    currentBrief: {
      type: Object,
      default: () => ({
        id: null,
        brand: null,
        budget: 0,
        region: [],
        campaignType: null,
        sec: [],
        ageGroup: [],
        gender: [],
        start_date: null,
        end_date: null,
        description: null,
      }),
    },
  },
  methods: {
    parseBudget() {
      if (!this.currentBrief.budget) return 0;
      return new Intl.NumberFormat().format(this.currentBrief.budget);
    },

    duration() {
      let end = dayjs(this.currentBrief.end_date);
      let start = dayjs(this.currentBrief.start_date);

      let diff = end.diff(start, "M");

      if (!diff) return 0;
      return diff;
    },
  },
};
</script>

<style scoped>
#bus {
  font-family: Manrope;
}

.v-list {
  display: flex;
  flex-direction: column;
}
.v-list-item-span {
  color: var(--v-textGray-base) !important;
}
</style>
