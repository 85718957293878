<template>
  <v-expansion-panels flat multiple v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header> Supplier </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-autocomplete
          v-model="searchQuery.supplier"
          auto-select-first
          chips
          clearable
          deletable-chips
          multiple
          small-chips
          cache-items
          solo-inverted
          hide-no-data
          :items="getSuppliers"
          @change="fetchSearch"
        ></v-autocomplete>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Site Run Up </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-slider
          v-model="searchQuery.site_run_up"
          step="10"
          min="0"
          max="500"
          @change="fetchSearch"
        >
          <template v-slot:append>
            <v-text-field
              v-model="searchQuery.site_run_up"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              suffix="m"
              style="width: 70px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Price </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              v-model.trim="searchQuery.rate_card.min"
              min="0"
              type="number"
              filled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-text-field
              v-model.trim="searchQuery.rate_card.max"
              min="0"
              max="1000000"
              type="number"
              suffix="R"
              filled
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn width="100" @click="fetchSearch">Go</v-btn>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Height </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-slider
          v-model="searchQuery.height"
          min="0"
          max="20"
          @change="fetchSearch"
        >
          <template v-slot:append>
            <v-text-field
              v-model="searchQuery.height"
              class="mt-0 pt-0"
              hide-details
              single-line
              suffix="m"
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Length </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-slider
          v-model="searchQuery.length"
          min="0"
          max="12"
          @change="fetchSearch"
        >
          <template v-slot:append>
            <v-text-field
              v-model="searchQuery.length"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              suffix="m"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Width </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-slider
          v-model="searchQuery.width"
          min="0"
          max="20"
          @change="fetchSearch"
        >
          <template v-slot:append>
            <v-text-field
              v-model="searchQuery.width"
              class="mt-0 pt-0"
              hide-details
              single-line
              suffix="m"
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Area Name </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row class="my2">
          <gmap-autocomplete
            placeholder="Enter Address"
            class="v-text-field v-text-field-outlined"
            @place_changed="setPlace"
            id="address"
            :options="autoCompleteBounds"
          >
          </gmap-autocomplete>
        </v-row>

        <v-row class="my2">
          <v-slider
            v-model="searchQuery.withinAddress.radius"
            min="200"
            step="200"
            max="3000"
            @change="fetchSearch"
          >
            <template v-slot:append>
              <v-text-field
                v-model="searchQuery.withinAddress.radius"
                class="mt-0 pt-0"
                hide-details
                single-line
                suffix="m"
                type="number"
                style="width: 80px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-row>

        <v-row class="my2" justify="center">
          <v-btn width="100" @click="fetchSearch">Go</v-btn>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Points of Interest </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-slider
            v-model="searchQuery.nearPois.radius"
            min="200"
            step="100"
            max="1000"
            @change="fetchSearch"
          >
            <template v-slot:append>
              <v-text-field
                v-model="searchQuery.nearPois.radius"
                class="mt-0 pt-0"
                hide-details
                single-line
                suffix="m"
                type="number"
                style="width: 76px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="searchQuery.nearPois.pois"
              v-for="(k, v) in pois"
              :key="k"
              :label="v"
              color="secondary"
              :value="k"
              @change="fetchSearch"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      searchQuery: {
        supplier: [],
        site_run_up: 0,
        rate_card: { min: 0, max: 0 },
        height: 0,
        length: 0,
        width: 0,
        withinAddress: { lat: 0, lng: 0, radius: 1000 },
        nearPois: { pois: [], radius: 200 },
      },

      panel: [0],

      filters: {},

      pois: {
        Atm: "atm",
        Bank: "bank",
        Market: "marketplace",
        Pharmacy: "pharmacy",
        Hospital: "hospital",
        Police: "police",
        School: "school",
        College: "college",
        University: "university",
        Restaurant: "restaurant",
        Bar: "bar",
        "Fuel Station": "fuel",
        Parking: "parking",
        "Bus Station": "bus_station",
        Theatre: "theatre",
        "Events Venue": "events_venue",
        "Public Building": "public_building",
        "Worship Place": "place_of_worship",
      },

      autoCompleteBounds: {
        bounds: {
          north: -28.4792625,
          south: -34.833239,
          east: 32.89116,
          west: 16.454482,
        },
        strictBounds: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      getSuppliers: "billboards/getSuppliers",
    }),
  },
  methods: {
    ...mapActions({
      searchBillboards: "billboards/searchBillboards",
      fetchSuppliers: "billboards/fetchSuppliers",
    }),

    async fetchSearch() {
      await this.searchBillboards(this.searchQuery);
    },

    async setPlace(place) {
      if (!place) return;
      this.searchQuery.withinAddress.lat = place.geometry.location.lat();
      this.searchQuery.withinAddress.lng = place.geometry.location.lng();
    },
  },
  async mounted() {
    this.loading = true;
  },
};
</script>

<style scoped>
.v-expansion-panel {
  margin-top: 10px;
}
.v-expansion-panel-content {
  padding: 1em 0 0 !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}
.v-input__slot {
  padding: 0 !important;
  margin: 0 !important;
}
.theme--light.v-messages {
  display: none;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 2em;
}

.v-expansion-panel-header {
  padding: 0;
}

#address {
  width: 96%;
  padding: 0 5px 0 10px;
  min-height: 3.2em;
  border-bottom: 1px solid grey;
}
</style>
