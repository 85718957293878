<template>
  <v-card-text class="features">
    <div>
      Address:
      <b>
        {{ currentBillboard.address }}
      </b>
    </div>

    <div>
      Price:
      <b>R {{ parseNumber(currentBillboard.rate_card) }} </b>
    </div>

    <div>
      Size:
      <b> {{ currentBillboard.length }} * {{ currentBillboard.width }} m</b>
    </div>

    <div>
      Height: <b> {{ currentBillboard.height }} m</b>
    </div>
  </v-card-text>
</template>

<script>
export default {
  props: {
    currentBillboard: { type: Object, required: true },
  },
  methods: {
    parseNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    },
  },
};
</script>

<style scoped>
.features > div {
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 13px;
}
</style>
