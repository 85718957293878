<template>
  <v-card width="100%">
    <v-data-table
      :items="currentCampaign.billboards"
      :headers="headers"
      sort-by="name"
      class="elevation-1 pa-3"
      :loading="!currentCampaign.billboards.length"
      loading-text="Fetching Billboards... Please wait"
    >
      <template v-slot:[`item.number`]="{ _, index }">
        {{ _ }} {{ index + 1 }}
      </template>

      <template v-slot:[`item.images`]="{ item }">
        <div class="p-2 images">
          <div v-viewer v-if="item.images.length" class="images">
            <img
              alt="Billboard Image"
              class="image"
              v-for="img in item.images"
              :key="img.id"
              :src="img.path"
              :lazy-src="img.path"
            />
          </div>
          <div v-viewer v-else class="images">
            <img
              class="white--text align-end image"
              src="/images/billboard.jpg"
              alt="Billboard Image"
            />
          </div>
        </div>
      </template>
    </v-data-table>
    <alert></alert>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "#", align: "start", sortable: false, value: "number" },
        { text: "Image", sortable: false, value: "images" },
        { text: "Price(R)", value: "rate_card" },
        { text: "Length (Metres)", value: "length" },
        { text: "Width (Metres)", value: "width" },
        { text: "Height", value: "height" },
        { text: "Orientation", value: "orientation" },
        { text: "Site Run Up(m)", value: "site_run_up" },
        { text: "Address", value: "address" },
      ],
    };
  },

  props: {
    currentCampaign: {
      type: Object,
      default: () => ({
        id: null,
        budget: null,
        duration: null,
        start_date: null,
        end_date: null,
        name: null,
        billboards: [
          {
            id: null,
            latitude: null,
            longitude: null,
            images: [{ path: null }],
          },
        ],
        planner: {
          user: {
            organization: null,
            planner_name: null,
          },
        },
        brand: {
          name: null,
        },
      }),
      required: true,
    },
  },
};
</script>

<style scoped>
.images {
  /* overflow-x: scroll; */
  overflow: hidden;
  max-width: 5em;
}
.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  /* position: absolute; */
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
</style>
