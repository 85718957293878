<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="py-5 px-2 mx-auto"
  >
    <v-toolbar flat class="mb-2">
      <v-toolbar-title>Current Briefs</v-toolbar-title>
    </v-toolbar>
    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    ></v-progress-linear>

    <v-data-table
      :items="getBriefs"
      :headers="headers"
      :search="search"
      sort-by="companyName"
      class="elevation-1 pa-3"
      loading-text="Fetching Briefs... Please wait."
      no-data-text="There are no briefs currently"
    >
      <template slot="top">
        <v-toolbar flat>
          <v-btn color="expansionPanels" @click="fetchBriefs">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="40%" class="mx-auto mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        {{ duration(item.start_date, item.end_date) }} Days
      </template>

      <template v-slot:[`item.budget`]="{ item }">
        <span id="formatBudget">
          {{ formatBudget(item.budget) }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="actions">
          <v-btn
            icon
            v-if="!viewOnCampaignsDialog"
            @click.stop="(currentBrief = item), (showBriefDetailsDialog = true)"
          >
            <v-icon color="secondary" title="More Information">
              mdi-eye
            </v-icon>
          </v-btn>

          <v-btn
            icon
            v-if="!viewOnCampaignsDialog"
            @click.stop="(currentBrief = item), (showEditBriefForm = true)"
          >
            <v-icon title="Edit" color="green">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="!viewOnCampaignsDialog"
            icon
            @click.stop="deleteItem(item)"
          >
            <v-icon title="Delete" color="red">mdi-delete</v-icon>
          </v-btn>

          <v-btn
            id="createCampaign"
            small
            elevation="0"
            v-if="viewOnCampaignsDialog"
            @click="selectBrief(item)"
          >
            Select for Campaign
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="showEditBriefForm" width="80%">
      <v-toolbar>
        <v-spacer></v-spacer>

        <v-btn icon @click="showEditBriefForm = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card elevation="0">
        <create-brief
          :currentBrief="currentBrief"
          operation="update"
        ></create-brief>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-progress-linear
          color="deep-purple accent-4"
          :active="loading"
          :indeterminate="loading"
        ></v-progress-linear>

        <v-card-title class="text-h5"
          >Are you sure you want to delete this Brief?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteBbConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showBriefDetailsDialog" width="60%">
      <v-card width="100%" elevation="0">
        <v-toolbar elevation="0">
          <v-spacer></v-spacer>

          <v-btn icon @click="showBriefDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div>
          <brief-details :currentBrief="{ ...currentBrief }"></brief-details>
        </div>
      </v-card>
    </v-dialog>

    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("briefs");

import { reportSuccess } from "@/helpers/utils.js";
import CreateBrief from "./CreateBrief.vue";
import BriefDetails from "@/components/briefs/BriefDetails.vue";

import * as dayjs from "dayjs";

export default {
  data() {
    return {
      search: "",
      loading: false,

      headers: [
        { text: "Company Name", align: "start", value: "organization" },
        { text: "Brand", value: "brand" },
        { text: "Client Rep", value: "client" },
        { text: "Duration", value: "duration" },
        { text: "Budget(R)", value: "budget" },
        { text: "Campaign Type", value: "campaignType" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      showEditBriefForm: false,
      showBriefDetailsDialog: false,
      deleteDialog: false,

      currentBrief: null,
    };
  },

  props: { viewOnCampaignsDialog: Boolean },

  components: { CreateBrief, BriefDetails },

  computed: {
    ...mapGetters(["getBriefs", "getBriefsCart"]),
  },

  emits: ["select-brief"],

  methods: {
    ...mapActions([
      "addBriefToCart",
      "fetchBriefs",
      "fetchAllBriefs",
      "deleteBrief",
    ]),

    formatBudget(budget) {
      return new Intl.NumberFormat().format(budget);
    },

    duration(start_date, end_date) {
      let end = dayjs(end_date);
      let start = dayjs(start_date);

      let diff = end.diff(start, "d");

      if (!diff) return 0;
      return diff;
    },

    async selectBrief(brief) {
      await this.addBriefToCart(brief);

      reportSuccess(
        "Brief selected successfully. Its details are on the panel on the left."
      );

      this.$emit("select-brief");
    },

    closeDialog() {
      this.showEditBriefForm = false;
    },

    deleteItem(brief) {
      this.currentBrief = brief;
      this.deleteDialog = true;
      this.loading = true;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    async deleteBbConfirm() {
      await this.deleteBrief(this.currentBrief.id);
      this.closeDelete();
      this.loading = false;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDelete();
    },
    viewOnCampaignsDialog: {
      immediate: true,
      async handler(val) {
        if (!val) return;
        this.loading = true;
        await this.fetchAllBriefs();
        this.loading = false;
      },
    },
  },

  async mounted() {
    if (!this.viewOnCampaignsDialog) {
      await this.fetchBriefs();
    }
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope;
}
.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}
.v-data-table-header {
  background: #f9f9fa;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#createCampaign {
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  padding: 15px 5px;

  color: #00a5ff;
  height: 24px;
}
#formatBudget {
  background: rgba(32, 201, 172, 0.1);
  height: 48px !important;
  color: #20c9ac;
  padding: 5px 14px;
}
.v-btn {
  margin: 15px 0;
  /* height: 32px !important; */
  /* width: 90%; */
  text-transform: none;
}
</style>
