<template>
  <v-card max-width="300">
    <v-navigation-drawer id="legend" permanent expand-on-hover right>
      <h3>Map Legend</h3>
      <div class="essentialLayers">
        <div>
          <img src="images/marker.png" alt="billboard" /> Billboards
          <input
            id="billboardChecked"
            checked
            type="checkbox"
            v-model="bbChecked"
            @change="checkBbs()"
          />
        </div>
      </div>
      <div class="mapLayers">
        <h5 class="accordion" ref="mapLayers">Map Layers</h5>
        <div class="accordion-content">
          <div>
            Traffic Layer
            <input
              id="trafficChecked"
              type="checkbox"
              v-model="trafficChecked"
              @change="checkTraffic()"
            />
          </div>
        </div>
      </div>
      <div class="poiLayers">
        <h5 class="accordion" ref="poiLayers">POIs</h5>
        <div class="accordion-content">
          <div v-for="(k, v) in poiLayers" :key="v">
            <img :src="`/images/${k}.png`" :alt="k" />
            {{ v }}
            <input
              :id="`${v}Checked`"
              class="poi"
              type="checkbox"
              @change="checkPoi()"
              :value="k"
              v-model="checkedPoi"
            />
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      mainLayer: ["billboards"],

      poiLayers: {
        atm: "atm",
        bank: "bank",
        market: "marketplace",
        pharmacy: "pharmacy",
        hospital: "hospital",
        police: "police",
        school: "school",
        college: "college",
        university: "university",
        restaurant: "restaurant",
        bar: "bar",
        "Fuel Station": "fuel",
        parking: "parking",
        "Bus Station": "bus_station",
        theatre: "theatre",
        "Events Venue": "events_venue",
        "Public Building": "public_building",
        "Worship Place": "place_of_worship",
      },

      bbChecked: true,
      trafficChecked: false,
      ghChecked: false,
      checkedPoi: [],
    };
  },
  emits: ["enable-poi"],
  methods: {
    checkPoi() {
      this.$emit("enable-poi", this.checkedPoi);
    },
    checkBbs() {
      this.$emit("enable-bb", this.bbChecked);
    },
    checkTraffic() {
      this.$emit("enable-traffic", this.trafficChecked);
    },
  },
};
</script>

<style scoped>
#legend {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #fff176;
  color: #050303;
  padding: 5px;
  font-weight: bold;
  width: 30px;
  max-height: 80%;
  text-align: center;
  max-width: 270px;

  overflow-y: scroll;
}
.legend--img {
  width: 20px;
  height: 20px;
}

.essentialLayers > div,
.aqLayers > div,
.accordion-content > div,
.eablpoiLayer > div {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  align-items: center;
  font-size: 11px;
  text-transform: capitalize;
}
h5.accordion {
  text-align: center;
  width: 100%;
  background-color: whitesmoke;
  border: none;
  outline: none;
  margin: 5px 0 0 0;
  font-size: 13px;
  color: #745141;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.accordion-content {
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 20px;
  /* max-height: 0; */
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.essentialLayers img,
.poiLayers img,
.mapLayers img,
.aqLayers img,
.eablpoiLayer img {
  height: 20px;
}
#legend > div:last-child {
  margin-bottom: 20px;
}
#legend h3,
#infoTab h3 {
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: center;
}
#legend img {
  vertical-align: middle;
}
#icons8 {
  height: 10px;
  padding: 1px;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
