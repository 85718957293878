<template>
  <v-card
    width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="py-5 px-2 mx-auto"
  >
    <v-toolbar flat class="mb-2">
      <v-toolbar-title>Current Campaigns</v-toolbar-title>
    </v-toolbar>

    <v-data-table
      :items="getCampaigns"
      :headers="headers"
      :search="search"
      sort-by="plan_name"
      class="elevation-1 pa-3"
      no-data-text="You have no running campaigns yet."
      loading-text="Fetching Campaigns... Please wait."
    >
      <template slot="top">
        <v-toolbar flat>
          <v-btn color="expansionPanels" @click="fetchCampaigns">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="40%" class="mx-auto mb-2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-responsive>
        </v-toolbar>
      </template>

      <template v-slot:[`item.organization`]="{ item }">
        {{ getOrg(item.planner) }}
      </template>

      <template v-slot:[`item.planner`]="{ item }">
        {{ getPlanner(item.planner) }}
      </template>

      <template v-slot:[`item.budget`]="{ item }">
        <span id="formatBudget">
          {{ parseNumber(item.brief.budget) }}
        </span>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        {{ getDuration(item.brief.end_date, item.brief.start_date) }} Days
      </template>

      <template v-slot:[`item.sites`]="{ item }">
        {{ item.billboards.length }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="!viewOnMonitoring"
          icon
          @click.stop="selectCampaign(item.id), (showCampaignDialog = true)"
        >
          <v-icon color="secondary" title="More Information"> mdi-eye </v-icon>
        </v-btn>

        <v-btn icon v-if="!viewOnMonitoring">
          <v-icon title="Edit" color="success">mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon v-if="!viewOnMonitoring">
          <v-icon title="Delete" color="error">mdi-delete</v-icon>
        </v-btn>

        <v-btn
          id="selectCurrentCampaign"
          small
          elevation="0"
          v-if="viewOnMonitoring"
          @click="selectCampaign(item.id)"
        >
          Select Campaign
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="showCampaignDialog" width="90%">
      <v-toolbar>
        <v-spacer></v-spacer>

        <v-btn icon @click="showCampaignDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <campaign-item></campaign-item>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import * as dayjs from "dayjs";
import CampaignItem from "./CampaignItem.vue";

export default {
  components: { CampaignItem },
  data() {
    return {
      search: "",
      loading: false,

      currentCampaignID: null,
      showCampaignDialog: false,

      headers: [
        { text: "Company Name", align: "start", value: "organization" },
        { text: "Campaign Name", value: "name" },
        { text: "Campaign By", value: "planner" },
        { text: "Duration", value: "duration" },
        { text: "Budget(R)", value: "budget" },
        { text: "Sites", value: "sites" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCampaigns: "campaigns/getCampaigns",
      getCurrentCampaign: "campaigns/getCurrentCampaign",
    }),
  },

  props: { viewOnMonitoring: Boolean },

  emits: ["select-campaign"],

  methods: {
    ...mapMutations({ setCurrentCampaign: "campaigns/setCurrentCampaign" }),

    ...mapActions({ fetchCampaigns: "campaigns/fetchCampaigns" }),

    getDuration(end, start) {
      let end_date = dayjs(end);
      let start_date = dayjs(start);

      let diff = end_date.diff(start_date, "d");

      if (!diff) return 0;
      return this.parseNumber(diff);
    },
    getOrg(planner) {
      return planner.user.organization;
    },
    getPlanner(planner) {
      return planner.user.plannerName;
    },
    parseNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    },
    selectCampaign(campaign) {
      this.setCurrentCampaign(campaign);

      this.$emit("select-campaign");
    },
  },
  async mounted() {
    await this.fetchCampaigns();
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope;
}
.v-toolbar {
  font-size: 24px;
  margin: 0 !important;
}
.v-data-table-header {
  background: #f9f9fa;
}
#formatBudget {
  background: rgba(32, 201, 172, 0.1);
  height: 48px !important;
  color: #20c9ac;
  padding: 5px 14px;
}
.v-data-table__wrapper tr {
  min-height: 70px !important;
}

#selectCurrentCampaign {
  text-transform: none;
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  padding: 15px 5px;

  color: #00a5ff;
  height: 24px;
}
.v-btn {
  margin: 15px 0;
  text-transform: none;
}
</style>
