var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-5 px-2 mx-auto",attrs:{"width":"100%","min-height":"650","id":"bus","elevation":"0"}},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Current Campaigns")])],1),_c('v-data-table',{staticClass:"elevation-1 pa-3",attrs:{"items":_vm.getCampaigns,"headers":_vm.headers,"search":_vm.search,"sort-by":"plan_name","no-data-text":"You have no running campaigns yet.","loading-text":"Fetching Campaigns... Please wait."},scopedSlots:_vm._u([{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOrg(item.planner))+" ")]}},{key:"item.planner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPlanner(item.planner))+" ")]}},{key:"item.budget",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"id":"formatBudget"}},[_vm._v(" "+_vm._s(_vm.parseNumber(item.brief.budget))+" ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDuration(item.brief.end_date, item.brief.start_date))+" Days ")]}},{key:"item.sites",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billboards.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.viewOnMonitoring)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.selectCampaign(item.id), (_vm.showCampaignDialog = true)}}},[_c('v-icon',{attrs:{"color":"secondary","title":"More Information"}},[_vm._v(" mdi-eye ")])],1):_vm._e(),(!_vm.viewOnMonitoring)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Edit","color":"success"}},[_vm._v("mdi-pencil")])],1):_vm._e(),(!_vm.viewOnMonitoring)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Delete","color":"error"}},[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.viewOnMonitoring)?_c('v-btn',{attrs:{"id":"selectCurrentCampaign","small":"","elevation":"0"},on:{"click":function($event){return _vm.selectCampaign(item.id)}}},[_vm._v(" Select Campaign ")]):_vm._e()]}}],null,true)},[_c('template',{slot:"top"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"expansionPanels"},on:{"click":_vm.fetchCampaigns}},[_vm._v(" Refresh "),_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-spacer'),_c('v-responsive',{staticClass:"mx-auto mb-2",attrs:{"max-width":"40%"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],2),_c('v-dialog',{attrs:{"width":"90%"},model:{value:(_vm.showCampaignDialog),callback:function ($$v) {_vm.showCampaignDialog=$$v},expression:"showCampaignDialog"}},[_c('v-toolbar',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showCampaignDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('campaign-item')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }