<template>
  <v-container fluid>
    <div v-if="!!billboards.length">
      <v-row>
        <p>Showing {{ billboards.length }} Billboards</p>
      </v-row>

      <v-row
        class="bbRow"
        v-for="bb in billboards"
        :key="bb.id"
        justify="center"
        align="center"
      >
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <div class="p-2">
            <div v-viewer class="images">
              <img
                class="image"
                v-if="showImages"
                src="/images/billboard.jpg"
                alt="Billboard Image"
              />

              <img
                v-else
                class="image"
                v-for="img in bb.images"
                :key="img.id"
                :src="img.path"
                alt="Billboard Image"
                :lazy-src="img.path"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
          <v-row justify="center" align="center">
            <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
              <p>
                <span class="search--cat--title">Dimensions</span>
                {{ bb.length }} * {{ bb.width }} (m)
              </p>
              <p>
                <span class="search--cat--title">Height: </span>{{ bb.height }}
              </p>
              <p>
                <span class="search--cat--title">Price: </span>R
                {{ bb.rate_card }}
              </p>
            </v-col>

            <v-col cols="5" xs="12" sm="12" md="5" lg="5" xl="5">
              <p>
                <span class="search--cat--title">Orientation: </span
                >{{ bb.orientation }}
              </p>

              <p>
                <span class="search--cat--title">Site Run Up: </span
                >{{ bb.site_run_up }}m
              </p>

              <p>
                <span class="search--cat--title">Address: </span
                >{{ bb.address }}
              </p>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="2"
              lg="2"
              xl="2"
              v-if="viewOnCampaignCreation"
            >
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="addToCart(bb)"> Book Now </v-btn>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog max-width="450" v-model="showCartDialog">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>Billboard has been added to cart.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn width="200" @click="showCartDialog = false" outlined>
              Continue Browsing</v-btn
            >
            <v-btn color="secondary" width="100" to="/cart">Checkout</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <p>There are no billboards that meet your criteria.</p>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventBus from "@/components/events/EventBus";

export default {
  data() {
    return {
      showCartDialog: false,
      billboards: [],
    };
  },

  props: {
    viewOnCampaignCreation: Boolean,
  },
  computed: {
    ...mapGetters({
      getSearchResults: "billboards/getSearchResults",
    }),
    showImages() {
      return !this.billboards?.images?.length;
    },
  },
  watch: {
    getSearchResults: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.billboards = val;
      },
    },
  },
  methods: {
    ...mapActions({
      addBbToCart: "billboards/addBbToCart",
      fetchBBPaginated: "billboards/fetchBBPaginated",
    }),

    async addToCart(bb) {
      await this.addBbToCart(bb.id);
      this.showCartDialog = true;

      EventBus.$emit("bbPrice", bb.rate_card);
    },
  },
  async mounted() {
    if (!this.getSearchResults.length) {
      const page = Math.floor(Math.random() * 80);
      let results = await this.fetchBBPaginated(page);
      this.billboards = results.billboards;
    }
  },
};
</script>

<style scoped>
.bbRow {
  /* border-bottom: 2px solid #ddb4b4; */
  border-top: 2px solid #b39184;
}
.image {
  max-width: 200px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}

.search--cat--title {
  color: black;
}
</style>
